import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedInUser: {},
    MFEComponentUpdateCounter: 0,
    loader: {
      isVisible: false,
      content: "Loading ..."
    }
  },
  mutations: {
    storeLoggedInUser(state: any, payload: { [key: string]: any }) {
      state.loggedInUser = payload;
    },
    updateMFEComponentUpdateCounter(state: any) {
      state.MFEComponentUpdateCounter = Date.now();
    },
    toggleLoader(state: any, payload: boolean) {
      state.loader.isVisible = payload;
    }
  },
  actions: {},
  modules: {}
});
