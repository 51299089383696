import Vue from "vue";
import AuthLayerMFE from "@canvas/authlayermfe";
import VueRouter from "@canvas/vue-router";
import { constructMfeRoutes } from "@canvas/mfe";

import App from "./App.vue";
import store from "./store";
import { initializeFaro } from "@grafana/faro-web-sdk";
Vue.use(VueRouter);

(window as any).Vue = Vue;  

Vue.config.productionTip = false;

fetch("/config.json")
  .then(response => response.json())
  .then(config => {
    const faro = initializeFaro({
      url: config.faro_config.url,
      apiKey: config.faro_config.apiKey,
      app: {
        name: config.faro_config.app_name,
        version: config.faro_config.app_version
      }
    });
    (window as any).faroObj = faro;

    let setupPromise = AuthLayerMFE.boot(null, config).then(() => {
      return AuthLayerMFE.sinklist.authorize
        .send({})
        .then(() => {
          return AuthLayerMFE.sinklist.loginstatus.send({});
        })
        .then((isLoggedIn: boolean) => {
          if (!isLoggedIn) {
            return Promise.reject("Not logged in!");
          } else {
            return AuthLayerMFE.sinklist.loggedInUser.send({});
          }
        })
        .catch((err: any) => {
          return Promise.reject("Not logged in!");
        });
    });

    setupPromise.then((UserDeatails: { [key: string]: any }) => {
      //store the logged in user
      store.commit("storeLoggedInUser", UserDeatails);
      AuthLayerMFE.sinklist.get
        .send({
          url: `/horreum/api/v1/jsons?system=MFE`
        })
        .then((res: any) => {
          const composerconfig = res.response.results.find(
            (composer: { [key: string]: any }) => {
              return composer.key === "vision-composer";
            }
          );
          const routes: any = constructMfeRoutes(
            composerconfig.value,
            AuthLayerMFE,
            (to: any, from: any, mfe: any) => {
              store.commit("toggleLoader", true);
              store.commit("updateMFEComponentUpdateCounter");
              if (mfe.emitterlist && mfe.emitterlist.getMfeCss) {
                mfe.emitterlist.getMfeCss.subscribe({
                  type: "function",
                  handler(data: any) {
                    return Promise.resolve(
                      composerconfig.value[mfe.name].styles
                    );
                  }
                });
              }
              if (mfe.emitterlist && mfe.emitterlist.getComposerConfig) {
                mfe.emitterlist.getComposerConfig.subscribe({
                  type: "function",
                  handler(data: any) {
                    return Promise.resolve(config);
                  }
                });
              }
            }
          );
          const router = new VueRouter({
            base: "/",
            mode: "history",
            routes
          });
          router.afterEach((to: any, from: any) => {
            setTimeout(() => {
              store.commit("toggleLoader", false);
            }, 500);
          });
          new Vue({
            router,
            store,
            render: h => h(App, { props: { config: composerconfig.value } })
          }).$mount("#app");
        });
    });
  });
