<template>
  <div :class="$style.stage" ref="stage">
    <PopupNotifications
      v-if="loader.isVisible"
      :separator="false"
      :closable="false"
      :title="''"
    >
      <div slot="body">
        <div :class="$style.modalContent">
          <div :class="$style.modalIconPadding">
            <AIcon :type="'IndeterminateLoader'"></AIcon>
          </div>
          <ATypography variant="headline5">{{ loader.content }}</ATypography>
        </div>
      </div>
    </PopupNotifications>
    <div id="app" :class="$style.app">
      <div :class="[$style.sidebar, { [$style.sidebarcollapsed]: true }]">
        <ALayoutSider>
          <template v-slot:header>
            <div :class="$style.sidebarmakecenter">
              <img :src="visionLogo" alt="Vision" height="30px" />
            </div>
          </template>
          <template v-slot:body>
            <div v-for="(mfe, i) in mfes" :key="i">
              <router-link
                :to="mfe.path"
                :active-class="$style.active"
                style="text-decoration:none"
              >
                <div :class="[$style.sidebarmakecenter, $style.sidebarmenu]">
                  <AIcon :type="mfe.icon" color="colorOnSecondary"></AIcon>
                </div>
              </router-link>
            </div>
          </template>
          <template v-slot:footer>
            <div
              @mouseleave="toggleSubmenu(false)"
              @click="toggleSubmenu(!isSubmenuVisibleBool)"
              style="width:100%"
            >
              <div>
                <AAvatar :src="loggedInUser.image_url">
                  {{ loggedInUser.name[0] }}
                </AAvatar>
              </div>
              <div
                :class="[
                  $style.sidebarSubmenu,
                  isSubmenuVisibleBool ? $style.submenuVisible : ''
                ]"
              >
                <div :class="$style.sidebarListItem" @click="logoutUser()">
                  <div :class="$style.flexcenter">
                    <div :class="$style.leftRightPadding">
                      <ATypography variant="bodydefault"
                        >Hi, {{ loggedInUser.name }}</ATypography
                      >
                    </div>
                    <div :class="$style.leftRightPadding">
                      <ATypography variant="bodydefault">Logout</ATypography>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ALayoutSider>
      </div>
      <div :class="[$style.tabarea, { [$style.sidebarcollapsed]: true }]">
        <div id="mfes">
          <router-mfe
            :useShadowDom="false"
            :shadowStyles="componentStyles"
            :customKey="MFEComponentUpdateCounter"
          ></router-mfe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ALayoutSider,
  AIcon,
  AAvatar,
  PopupNotifications,
  ATypography
} from "@canvas/componentlibrary";
import { mapState } from "vuex";
import { fetchAndLoadStyles } from "@canvas/mfe";

export default {
  components: {
    ALayoutSider,
    AIcon,
    AAvatar,
    PopupNotifications,
    ATypography
  },
  props: ["config"],
  data() {
    return {
      componentStyles: "",
      isSubmenuVisibleBool: false,
      visionLogo: require("@/assets/logo.png")
    };
  },
  computed: {
    ...mapState({
      loggedInUser: "loggedInUser",
      loader: "loader",
      MFEComponentUpdateCounter: "MFEComponentUpdateCounter"
    }),
    mfes() {
      return this.config.ordering.map(key => {
        return {
          path: this.config[key].path,
          icon: this.config[key].icon
        };
      });
    }
  },
  methods: {
    toggleSubmenu(boolValue) {
      this.isSubmenuVisibleBool = boolValue;
    },
    logoutUser() {
      this.$router.push({ path: "/logout" });
    }
  },
  mounted() {
    const mfeStyleSheets = [
      "https://naarad.livspace.com/canvas/componentlibrary/0.6.5/components.canvas.css"
    ];
    fetchAndLoadStyles(
      mfeStyleSheets,
      document.getElementsByTagName("head")[0]
    );
  }
};
</script>

<style lang="scss" module>
.sidebar {
  width: 250px;
}

.sidebar.sidebarcollapsed {
  width: 50px;
}

.sidebarSubmenu {
  position: absolute;
  left: 100%;
  bottom: 0;
  background-color: #26273d;
  display: none;
  &.submenuVisible {
    display: block;
  }
}

.sidebarListItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #2d2e42;
  }
}

.flexcenter {
  display: flex;
  flex-direction: column;
}

.leftRightPadding {
  padding: 8px 16px;
  width: max-content;
}

.flexitem {
  height: 100vh;
  flex-grow: 1;
}
.tabarea {
  width: calc(100% - 250px);
}

.tabarea.sidebarcollapsed {
  width: calc(100% - 50px);
}

.stage {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 100vw 100vw;
  grid-template-areas: "app";
}
.app {
  display: flex;
  width: 100%;
  grid-area: app;
  height: inherit;
}

.sidebarmakecenter {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.sidebarmenu {
  padding: 15px 0px;
  cursor: pointer;
  &:hover {
    background-color: #3655f2;
  }
}
.active > div {
  background-color: #3655f2;
}
.modalContent {
  display: flex;
  align-items: center;
}
.modalIconPadding {
  padding-right: 20px;
}
</style>
